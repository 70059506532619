import revive_payload_client_u8o5nYNnM6 from "/home/vsts/work/1/s/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@18.19.80_db0@0.3.1_ioredis@5.6.0_magicast_f1c0a91b9eb5c2c64b163a5997d602e2/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_RtcjyWO6Js from "/home/vsts/work/1/s/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@18.19.80_db0@0.3.1_ioredis@5.6.0_magicast_f1c0a91b9eb5c2c64b163a5997d602e2/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_szx19CBQGg from "/home/vsts/work/1/s/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@18.19.80_db0@0.3.1_ioredis@5.6.0_magicast_f1c0a91b9eb5c2c64b163a5997d602e2/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_G6pKEBoNoG from "/home/vsts/work/1/s/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@18.19.80_db0@0.3.1_ioredis@5.6.0_magicast_f1c0a91b9eb5c2c64b163a5997d602e2/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_V81gxidk1o from "/home/vsts/work/1/s/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@18.19.80_db0@0.3.1_ioredis@5.6.0_magicast_f1c0a91b9eb5c2c64b163a5997d602e2/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_TKjwPkjdY0 from "/home/vsts/work/1/s/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@18.19.80_db0@0.3.1_ioredis@5.6.0_magicast_f1c0a91b9eb5c2c64b163a5997d602e2/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_fyu9YMLLNY from "/home/vsts/work/1/s/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@18.19.80_db0@0.3.1_ioredis@5.6.0_magicast_f1c0a91b9eb5c2c64b163a5997d602e2/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/home/vsts/work/1/s/.nuxt/components.plugin.mjs";
import prefetch_client_r70CoIqvfx from "/home/vsts/work/1/s/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@18.19.80_db0@0.3.1_ioredis@5.6.0_magicast_f1c0a91b9eb5c2c64b163a5997d602e2/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import pwa_icons_plugin_LnrhIJeMG7 from "/home/vsts/work/1/s/.nuxt/pwa-icons-plugin.ts";
import pwa_client_w0vgIRcmdw from "/home/vsts/work/1/s/node_modules/.pnpm/@vite-pwa+nuxt@0.9.1_magicast@0.3.5_vite@6.2.1_@types+node@18.19.80_jiti@2.4.2_terser@5_47dcf6f472abbeb17f232095901d216f/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import i18n_5E1ezZtOMi from "/home/vsts/work/1/s/node_modules/.pnpm/@nuxtjs+i18n@8.0.2_magicast@0.3.5_rollup@4.35.0_vue@3.5.13_typescript@5.8.2_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import _1_application_insights_client_2isRgr4PXf from "/home/vsts/work/1/s/plugins/1.application-insights.client.ts";
import _2_auth_42b9fB0DY4 from "/home/vsts/work/1/s/plugins/2.auth.ts";
import _3_axios_5VOloTgdvi from "/home/vsts/work/1/s/plugins/3.axios.ts";
import error_handler_kEP5FliEXj from "/home/vsts/work/1/s/plugins/error-handler.ts";
import nuxt_link_import_yzIAaKi1HN from "/home/vsts/work/1/s/plugins/nuxt-link-import.ts";
import pwa_client_PLDhtd2phb from "/home/vsts/work/1/s/plugins/pwa.client.ts";
import router_watch_LdArCFqd7Z from "/home/vsts/work/1/s/plugins/router-watch.ts";
import sentry_logging_o4SBtmowK2 from "/home/vsts/work/1/s/plugins/sentry-logging.ts";
import vue_pdf_3byIq144W2 from "/home/vsts/work/1/s/plugins/vue-pdf.ts";
import vue_router_append_cjk9JpQRKU from "/home/vsts/work/1/s/plugins/vue-router-append.ts";
import vue_signature_pad_7H3GYOYmnY from "/home/vsts/work/1/s/plugins/vue-signature-pad.ts";
export default [
  revive_payload_client_u8o5nYNnM6,
  unhead_RtcjyWO6Js,
  router_szx19CBQGg,
  payload_client_G6pKEBoNoG,
  navigation_repaint_client_V81gxidk1o,
  check_outdated_build_client_TKjwPkjdY0,
  chunk_reload_client_fyu9YMLLNY,
  components_plugin_KR1HBZs4kY,
  prefetch_client_r70CoIqvfx,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_w0vgIRcmdw,
  i18n_5E1ezZtOMi,
  _1_application_insights_client_2isRgr4PXf,
  _2_auth_42b9fB0DY4,
  _3_axios_5VOloTgdvi,
  error_handler_kEP5FliEXj,
  nuxt_link_import_yzIAaKi1HN,
  pwa_client_PLDhtd2phb,
  router_watch_LdArCFqd7Z,
  sentry_logging_o4SBtmowK2,
  vue_pdf_3byIq144W2,
  vue_router_append_cjk9JpQRKU,
  vue_signature_pad_7H3GYOYmnY
]